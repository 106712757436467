<template lang="pug">
  .box_size
    h4.center-text.mt-2(style="font-size: 1.1rem;" v-for="(line, i) in lines" :key="'line_' + i") {{ line }}
    h5.center-text.mt-4(style="font-size: 1rem;") {{ date }}
    //- .Footer
</template>

<script>
import Header from "./components/Header";
import moment from "moment";

export default {
  name: "TicketPrint",

  components: {
    Header
  },

  computed: {
    lines() {
      return this.$route.query?.ev || [];
    },

    date() {
      return moment().format("YYYY-MM-DD hh:mm a");
    }
  }
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.box_size {
  width: 60mm;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
</style>
